import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import TeamMemberCard, { MemberShape } from './TeamMemberCard';
import './AboutPage.module.css';

const AboutContentShape = PropTypes.shape({
  founders: PropTypes.arrayOf(MemberShape),
  board: PropTypes.arrayOf(MemberShape),
  advisors: PropTypes.arrayOf(MemberShape),
});

const AboutPage = ({
  intl,
  content: {
    founders,
    board,
    advisors,
  },
}) => (
  <div styleName="container">
    <div styleName="hero">
      <Container>
        <h4>
          {intl.formatMessage({ id: 'about.title' })}
        </h4>

        <div styleName="about-section">
          <h5>
            {intl.formatMessage({ id: 'about.section-one.title' })}
          </h5>

          <p>
            {intl.formatMessage({ id: 'about.section-one.description' })}
          </p>
        </div>
      </Container>
    </div>

    <Container>
      <section styleName="our-team">
        <h4>
          {intl.formatMessage({ id: 'about.founders' })}
        </h4>

        <div styleName="team-members" data-type="founders">
          {founders.map((founder) => (
            <TeamMemberCard member={founder} key={founder.name} />
          ))}
        </div>

        <h4>
          {intl.formatMessage({ id: 'about.board' })}
        </h4>

        <div styleName="team-members">
          {board.map((member) => (
            <TeamMemberCard member={member} key={member.name} />
          ))}
        </div>

        <h4>
          {intl.formatMessage({ id: 'about.advisors' })}
        </h4>

        <div styleName="team-members">
          {advisors.map((member) => (
            <TeamMemberCard member={member} key={member.name} />
          ))}
        </div>

      </section>
    </Container>
  </div>
);

AboutPage.propTypes = {
  intl: intlShape.isRequired,
  content: AboutContentShape.isRequired,
};

export default injectIntl(AboutPage);
