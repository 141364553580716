import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Modal from 'react-overlays/Modal';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import PlusIcon from 'svg/plus-solid.svg';
import MinusIcon from 'svg/minus-solid.svg';
import X from 'svg/times.svg';
import GitHub from 'svg/github-brands.svg';
import SoundCloud from 'svg/soundcloud-brands.svg';
import Twitter from 'svg/twitter-brands.svg';
import YouTube from 'svg/youtube-brands.svg';
import LinkedIn from 'svg/linkedin-brands.svg';
import './TeamMemberCard.module.css';

const LINK_ICONS = {
  github: GitHub,
  soundcloud: SoundCloud,
  twitter: Twitter,
  youtube: YouTube,
  linkedin: LinkedIn,
};

function ModalBackdrop(props) {
  return (
    <div
      styleName="modal-backdrop"
      {...props}
    />
  );
}

function Bio({ name, text, intl }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div styleName="bio">
      <button type="button" onClick={() => setExpanded(!expanded)}>
        {expanded ? <MinusIcon /> : <PlusIcon />}

        {expanded ? (
          intl.formatMessage({ id: 'about.hide' })
        ) : (
          intl.formatMessage({ id: 'about.more' })
        )}
      </button>

      <Modal
        show={expanded}
        onHide={() => setExpanded(false)}
        renderBackdrop={ModalBackdrop}
        aria-labelledby="modal-label"
        className="bio-modal"
        container={() => document.getElementById('___gatsby')}
      >
        <div styleName="modal-content">
          <button
            type="button"
            styleName="modal-close-button"
            onClick={() => setExpanded(false)}
          >
            <X />
          </button>

          <h5 id="bio-modal">
            {name}
          </h5>

          <p>
            {text}
          </p>
        </div>
      </Modal>
    </div>
  );
}

Bio.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

const TeamMemberCard = ({
  intl,
  member: {
    name,
    title,
    bio,
    img,
    links,
  },
}) => (
  <div styleName="team-member" key={name}>
    <Img
      fixed={img.childImageSharp.fixed}
      objectFit="cover"
      objectPosition="50% 50%"
    />

    <div styleName="info">
      <div styleName="name">
        <strong>
          {name}
        </strong>

        {title && (
          <div styleName="title">
            {intl.formatMessage({ id: `about.roles.${title}` })}
          </div>
        )}

        <span />
      </div>

      <div style={{ display: 'flex' }}>
        {bio && (
          <Bio name={name} text={bio} intl={intl} />
        )}
        {links && (
          <div style={{ display: 'flex', gridGap: '0.25em', alignItems: 'center' }}>
            {links.map(({ type, url }) => {
              const Icon = LINK_ICONS[type];
              return (
                <a
                  style={{ display: 'flex' }}
                  href={url}
                  rel="noreferrer nofollow"
                  styleName="link"
                  key={url}
                >
                  <Icon />
                </a>
              );
            })}
          </div>
        )}
      </div>

    </div>
  </div>
);

const LinkType = PropTypes.shape({
  type: PropTypes.oneOf(Object.keys(LINK_ICONS)).isRequired,
  url: PropTypes.string.isRequired,
});

export const MemberShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  img: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  bio: PropTypes.string,
  links: PropTypes.arrayOf(LinkType),
});

TeamMemberCard.propTypes = {
  member: MemberShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TeamMemberCard);
