/* eslint-disable react/prop-types */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import { useCompanyName } from 'hooks/companyName';
import Layout from 'components/Layout';
import AboutPage from 'components/pages/AboutPage';

const About = ({ data }) => (
  <Layout>
    <Helmet title={`About ${useCompanyName()}`} />

    <AboutPage content={data.allTeamJson.nodes[0]} />
  </Layout>
);

export const query = graphql`
  {
    allTeamJson {
      nodes {
        founders {
          name
          title
          bio
          links {
            type
            url
          }
          img {
            ...TeamMemberImage
          }
        }
        board {
          name
          bio
          links {
            type
            url
          }
          img {
            ...TeamMemberImage
          }
        }
        advisors {
          name
          bio
          links {
            type
            url
          }
          img {
            ...TeamMemberImage
          }
        }
      }
    }
  }
`;

export default About;
